// 获取code参数
export const getUrlCode = (_url: string): string => {
  if (_url.indexOf('code=') === -1) {
    return ''
  }
  const t = _url.match(/\?code=(\S*)&state=111/)
  const url = t && t.length && t[1]
  return unescape(url as string)
}

/**
 * 获取url hash部分backurl value
 */
export const hashGetBackUrl = (): string => {
  const urlHash = window.location.hash
  if (urlHash.indexOf('backUrl=') === -1) {
    return ''
  }
  const t = urlHash.split('backUrl=')
  const url = t[t.length - 1]
  return unescape(url as string)
}

// 是否是IOS终端/设备
export const isIos = (): boolean => {
  const u = navigator.userAgent
  const ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  if (ios) {
    return true
  }
  return false
}

// 是否是android终端/设备
export const isAndroid = (): boolean => {
  const u = navigator.userAgent
  const android = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
  if (android) {
    return true
  }
  return false
}
